import React from "react";
import Wallet, {
  Address,
  AddressPurpose,
  BitcoinNetworkType,
} from "sats-connect";
import { useLocalStorage } from "../../hooks";
import Banner from "../Banner/Banner";
import { useEffect, useState } from "react";
import DisplayTokens from "../DisplayTokens/DisplayTokens";

const Home = () => {
  const [network, setNetwork] = useLocalStorage<BitcoinNetworkType>(
    "network",
    BitcoinNetworkType.Mainnet
  );
  const [cmsText, setCMSText] = useState<any>({});
  const [addressInfo, setAddressInfo] = useLocalStorage<Address[]>(
    "addresses",
    []
  );

  useEffect(() => {
    // Fetch data from the API
    fetch(process.env.REACT_APP_CMS_BASE_URL + "/home")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Update state with the fetched data
        setCMSText(data?.data?.attributes);
        // setTodos(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  const isConnected = addressInfo.length > 0;
  const onConnect = async () => {
    const response = await Wallet.request("getAccounts", {
      purposes: [
        AddressPurpose.Payment,
        AddressPurpose.Ordinals,
        AddressPurpose.Stacks,
      ],
      message: "Cool app wants to know your addresses!",
    });
    if (response.status === "success") {
      setAddressInfo(response.result);
    }
  };

  return (
    <div className=" mb-[150px]  flex flex-col justify-center overflow-y-scroll items-center">
      <Banner cmsData={cmsText} onConnect={onConnect} canConnect={false} />
      <DisplayTokens cmsData={cmsText} />
    </div>
  );
};

export default Home;
