import React, { useContext, useState } from "react";
import MintRunes from "../../../MintRunes";
import { Address, BitcoinNetworkType } from "sats-connect";
import { useLocalStorage } from "../../../../hooks";
import { CmsContext } from "../../../../context/CmsContext";

interface RightSidebarProps {
  rightOpen: boolean;
  setRightOpen: any;
}

const RightSidebar: React.FC<RightSidebarProps> = ({
  rightOpen,
  setRightOpen,
}) => {
  const [step, setStep] = useState<number>(3);

  const exploreData = useContext(CmsContext);

  const [network, 
    // setNetwork
  ] = useLocalStorage<BitcoinNetworkType>(
    "network",
    BitcoinNetworkType.Mainnet
  );

  const [addressInfo, 
    // setAddressInfo
  ] = useLocalStorage<Address[]>(
    "addresses",
    []
  );

  return (
    <div
      className={` ${
        rightOpen ? "block" : " hidden"
      } right-0 absolute 2xl:static xs:overflow-hidden  h-full xss:h-[calc(100vh-160px)] sm:z-0 bg-black/40 backdrop-blur-[10px]`}
    >
      <div className=" relative bg-[#0F0F14]   my-[10px] h-[98%] border border-white/60 rounded-2xl px-3  py-8 flex flex-col gap-5  ">
        <div className=" flex overflow-x-scroll">
          <button
            type="button"
            className={` py-2 px-5 xll:px-7  font-bold font-monsterate text-sm leading-[25px] ${
              step === 3
                ? " text-[#D9D9D9] bg-[#24242D] rounded-md"
                : "text-sm text-[#7A7A8E] bg-[#030307] leading-[25px]"
            }  `}
            onClick={() => setStep(3)}
          >
            {exploreData?.exploreText?.mint}
          </button>
          <button
            type="button"
            className={` py-2 px-5 xll:px-7  font-bold font-monsterate text-sm leading-[25px] ${
              step === 1
                ? " text-[#D9D9D9] bg-[#24242D] rounded-md"
                : "text-sm text-[#7A7A8E] bg-[#030307] leading-[25px]"
            }  `}
            onClick={() => setStep(1)}
          >
            {exploreData?.exploreText?.buyRunes}
          </button>
          <button
            type="button"
            onClick={() => setStep(2)}
            className={` py-2 px-5 xll:px-7 font-bold font-monsterate text-sm leading-[25px] ${
              step === 2
                ? " text-[#D9D9D9] bg-[#24242D] rounded-md "
                : " text-[#7A7A8E] bg-[#030307]"
            }  `}
          >
            {exploreData?.exploreText?.sellRunes}
          </button>{" "}
        </div>

        {step === 1  || step === 2 ? (
          // <>
          //   <div className=" w-full flex justify-between items-center">
          //     <div className=" flex cursor-pointer font-bold gap-[2px] items-center relative ">
          //       <Select
          //         defaultValue="lowest-price"
          //         style={{ color: "white" }}
          //         onChange={handleChange}
          //         options={[
          //           { value: "lowest-price", label: "Lowest Price" },
          //           { value: "highest-price", label: "Highest Price" },
          //         ]}
          //       />
          //       <img
          //         src="/dropdown.svg"
          //         alt="dropdown"
          //         className=" absolute right-3 top-3"
          //         width={9}
          //         height={5}
          //       />
          //     </div>
          //     <div className=" flex gap-4">
          //       <div className=" flex  gap-3 text-white cursor-pointer">
          //         <p className=" text-[12px] text-[#7A7A8E] leading-[21px]">
          //         {exploreData?.exploreText?.filter}
          //         </p>
          //         <img
          //           src="/Filter.svg"
          //           alt="Filter"
          //           className=""
          //           width={23}
          //           height={15}
          //         />
          //       </div>
          //       <img
          //         src="/reset.svg"
          //         alt="reset"
          //         className=" cursor-pointer"
          //         width={23}
          //         height={15}
          //       />
          //     </div>
          //   </div>

          //   <div className=" text-[12px] text-[#7A7A8E] leading-[21px] flex justify-between items-center">
          //     <div>{exploreData?.exploreText?.amount}</div>
          //     <div>{exploreData?.exploreText?.cost}</div>
          //     <div>{exploreData?.exploreText?.total}</div>
          //   </div>

          //   <div className=" w-full  h-[calc(100vh-350px)] overflow-scroll flex flex-col gap-[30px]">
          //     <CardView />
          //     <div className=" pointer-events-none absolute z-10 bg-[#0F0F14]  bottom-20 opacity-60 right-6 w-[90%] h-[100px]"></div>
          //   </div>

          //   <button
          //     type="button"
          //     className={` w-full py-3 rounded-lg font-bold bg-custom-gradient`}
          //   >
          //     {exploreData?.exploreText?.buyNow}
          //   </button>
          // </>
          <div className=" w-full h-full flex justify-center items-center">
            <p className=" text-[24px] leading-7 bg-custom-gradient text-transparent bg-clip-text ">
              {exploreData?.cmsText?.comingSoon}
              </p>
          </div>
        ) : null}

        {step === 3 && (
          <div className="w-full h-[100px] flex flex-col gap-10 mt-5 text-white">
            <MintRunes
              cmsData={exploreData?.cmsText}
              network={network}
              addresses={addressInfo}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RightSidebar;