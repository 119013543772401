export async function makeApiRequest(rune: string, type: string, from: number, to: number, countBack: number): Promise<any> {
  // console.log(rune, "rune")
  try {
    const response = await fetch(`https://runes-backend-98a8e78fba0f.herokuapp.com/api/v1/chartData?rune=${rune}&type=${type}&from=${from}&to=${to}&countback=${countBack}`);

    return  response.json();
  } catch (error) {

    console.log("Eeerrrooorrr",error)
    throw new Error(`CryptoCompare request error: $}{error instanceof Error ? error.message : 'Unknown error'}`);
  }
}

// https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd

export async function getBtcValue(): Promise<any> {
  try {
    let urlforRunes = `https://api.coinlore.net/api/ticker/?id=90`;
    const response = await fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(urlforRunes)}`);
    return response.json();
  } catch (error) {
    console.log("Error:", error);
    throw new Error(`CryptoCompare request error: ${error instanceof Error ? error.message : 'Unknown error'}`);
  }
}

export function formatNumber(num: number): string {
  // Round the number to two decimal places before further processing
  num = parseFloat(num.toFixed(2));

  if (num >= 1e9) {
      return (num / 1e9).toFixed(2) + 'b';
  } else if (num >= 1e6) {
      return (num / 1e6).toFixed(2) + 'm';
  } else if (num >= 1e3) {
      return (num / 1e3).toFixed(2) + 'k';
  } else {
      return num.toFixed(2);
  }
}

