import { createApi } from "@reduxjs/toolkit/query/react";
import { rtkBaseQuery } from "../basequery";
import { io } from "socket.io-client";

export const runesApi = createApi({
  reducerPath: "runesApi",
  baseQuery: rtkBaseQuery,
  endpoints: (builder) => ({
    getRunesCollectionStats: builder.query({
      query: (data) => ({
        url: `v1/collectionStats`,
        params: { ...data },
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },

      merge: (currentCache, newItems, args: any) => {
        if (args?.arg?.offset !== 0) {
          currentCache.runes.push(...newItems?.runes);
        } else {
          currentCache.runes = newItems?.runes;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getMyRunes: builder.query({
      query: ({ address = "" }) => {
        return {
          url: `v1/myRunes`,
          params: {
            address,
          },
        };
      },
    }),
    getPoints: builder.query({
      query: ({ address ="" }) => ({
        url: `v1/getUserRuneData`,
        params: {
          address,
        },
      }),

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const socket = io(`${process.env.REACT_APP_SOCKET_URL}`);
        const { address } = arg;
        socket.on("connect", () => {
          socket.emit("register", address);
        });

        try {
          await cacheDataLoaded; // Wait for initial query to resolve

          const listener = (data: any) => {
            updateCachedData((draft) => {
              if( data ){
                draft.points = data.amount; // Update cached rune points data
                draft.pointsIncrement = data.pointsIncrement; // Update cached points increment data
              }
            });
          };

          socket.on("amountUpdated", listener); // Listen for amountUpdated event

          await cacheEntryRemoved; // Disconnect socket when cache entry is removed (component unmount)
          socket.off("amountUpdated", listener); // Remove listener for amountUpdated event
          socket.disconnect();
        } catch (err) {
          console.error("WebSocket connection error:", err);
        }
      },
    }),
  }),
});

export const {
  useGetRunesCollectionStatsQuery,
  useGetMyRunesQuery,
  useGetPointsQuery,
} = runesApi;
