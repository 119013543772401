import { useContext, useMemo, useState } from "react";
import Wallet, {
  Address,
  AddressPurpose,
  BitcoinNetworkType,
} from "sats-connect";
import SuccessModal from "../Modals/SuccessModal";
import toast, { Toaster } from "react-hot-toast";
import { CmsContext } from "../../context/CmsContext";
import React from "react";
import { useSelector } from "react-redux";

type Props = {
  network: BitcoinNetworkType;
  addresses: Address[];
  cmsData: any;
};

const MintRunes = ({ addresses, network, cmsData }: Props) => {
  const [open, setOpen] = useState<Boolean>(false);
  // const [isYourRune, setIsYourRune] = useState<Boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fundTxId, setFundTxId] = useState<string>("");
  // @typescript-eslint/no-unused-vars
  const [runeName, setRuneName] = useState<string>("");
  const [feeRate, setFeeRate] = useState<string>("");
  const [repeats, setRepeats] = useState<string>("");

  const {pointsText }: any = useContext(CmsContext);

  const selectedToken = useSelector((state: any) => state.global.selectedToken);

  const ordinalsAddress = useMemo(
    () =>
      addresses.find((a) => a.purpose === AddressPurpose.Ordinals)?.address ||
      "",
    [addresses]
  );

  const paymentAddress = useMemo(
    () =>
      addresses.find((a) => a.purpose === AddressPurpose.Payment)?.address ||
      "",
    [addresses]
  );

  // const onClickEstimate = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await Wallet.request("runes_estimateMint", {
  //       destinationAddress: ordinalsAddress,
  //       feeRate: +feeRate,
  //       repeats: +repeats,
  //       runeName: runeName,
  //       network: network,
  //     });

  //     if (response.status === "success") {
  //       setTotalCost(response.result.totalCost);
  //       setTotalSize(response.result.totalSize);
  //     } else {
  //       console.error(response.error);
  //     }
  //     onClickExecute();
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // };

  const onClickExecute = async () => {
    try {
      setLoading(true);
      const response = await Wallet.request("runes_mint", {
        destinationAddress: ordinalsAddress,
        feeRate: +feeRate,
        repeats: +repeats,
        runeName,
        refundAddress: paymentAddress,
        network,
      });
      setLoading(false);
      if (response.status === "success") {
        const url =
          network === BitcoinNetworkType.Mainnet
            ? `https://mempool.space/tx/${response.result.fundTransactionId}`
            : `https://mempool.space/testnet/tx/${response.result.fundTransactionId}`;
        setFundTxId(url);
        setOpen(true);
      } else {
        setLoading(false);
        console.error(response.error.message);
        toast.error(response.error.message);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="w-full  flex flex-col text-[12px] sm:text-[14px] items-center gap-10 px-3 sm:px-1 py-4 sm:py-6 card text-white bg-[#0F0F14] rounded-[30px]">
        {/* <div className="w-full transition-all duration-900  text-[12px] sm:text-[14px]  px-3 py-2 rounded-lg flex gap-5 bg-[#030307]">
          <button
            className={`w-full px-2 py-1 font-semibold rounded-lg ${
              !isYourRune ? "bg-[#24242D]" : ""
            }`}
            onClick={() => {
              setIsYourRune(false);
            }}
          >
            {cmsData?.mintABC}
          </button>
          <button
            className={`w-full px-2 py-1 font-semibold rounded-lg ${
              isYourRune ? "bg-[#24242D]" : ""
            }`}
            onClick={() => {
              setIsYourRune(true);
            }}
          >
            {cmsData?.mintYourRune}
          </button>
        </div> */}
        {/* {isYourRune && ( */}
        <>
          <div className="w-full flex flex-col gap-2">
            <div className="flex justify-between">
              <h4 className="font-bold font-monsterate">{cmsData?.runeName}</h4>
            </div>
            <div className=" relative">
              {/*<span className="flex h-full items-center absolute top-[0px] left-[20px] text-[16px] font-bold">
                $
              </span>
               <input
                  className="w-full px-[40px] py-3  outline-none hover:text-red rounded-md  bg-[#0F0F14]"
                  placeholder={cmsData?.runePlaceHolder}
                  type="text"
                  value={runeName}
                  onChange={(e) => setRuneName(e.target.value)}
                /> */}
              <div className="text-white font-monsterate text-2xl font-bold">
                {selectedToken?.etching?.runeName}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            <div className="w-full flex justify-between">
              <h4 className="font-bold font-monsterate">
                {cmsData?.amountField}
              </h4>
              <span className="text-[#5D5D6B] font-monsterate text-[13px]">
                {cmsData?.amountLabel}
              </span>
              {/* <div className="text-[14px] text-[#5D5D6B]">
            Current rate 94 sat/vB
            </div> */}
            </div>
            <div className="modal-content relative">
              <span className="flex h-full items-center absolute top-[0px] left-[15px] font-bold text-[#FFE564]">
                <img src={"/amount.svg"} alt={"icon"} />
              </span>
              <input
                className="w-full px-[40px] py-3  outline-none hover:text-red rounded-md  bg-[#0F0F14]"
                placeholder={cmsData?.amountPlaceHolder}
                type="number"
                value={repeats}
                onChange={(e) => setRepeats(e.target.value)}
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            <div className="flex justify-between">
              {/* <h4 className="font-bold">feeRate (sats/vb)</h4> */}
              <h4 className="font-bold font-monsterate">{cmsData?.btcFee}</h4>
              <span className="text-[#5D5D6B] font-monsterate text-[13px]">
                {cmsData?.feeLabel}
              </span>
            </div>
            <div className="modal-content relative">
              <span className="flex h-full items-center absolute top-[0px] left-[15px] font-bold text-[#FFE564]">
                <img src={"/fee.svg"} alt={"icon"} />
              </span>
              <input
                className="w-full px-[40px] py-3  outline-none hover:text-red rounded-md  bg-[#0F0F14]"
                placeholder={cmsData?.feePlaceHolder}
                type="number"
                value={feeRate}
                onChange={(e) => setFeeRate(e.target.value)}
              />
              <span className=" text-[#FDA2CE] text-sm absolute right-3 font-monsterate top-[13px] font-bold">
                sat/vB
              </span>
            </div>
          </div>

          <button
            onClick={onClickExecute}
            // disabled={ !feeRate || !repeats || loading}
            disabled={true}
            className="w-full h-[45px] flex justify-center font-monsterate font-bold rounded-xl cursor-pointer px-4 py-3 disabled:cursor-not-allowed disabled:opacity-60 text-black process-btn hover:shadow-glowShadow"
          >
            {/* {!loading ? (
              cmsData?.mintButton
            ) : (
              <img
                className="invert animate-spin"
                src={"/loading.png"}
                alt={"icon"}
              />
            )} */}
            {pointsText?.comingSoon}
          </button>
        </>
        {/* )} */}
        {/* {!isYourRune && <MintABC cmsData={cmsData} />} */}
        <div className="text-[#5D5D6B] flex items-center"></div>
      </div>
      <SuccessModal open={open} setOpen={setOpen} fundTxId={fundTxId} />
      <Toaster />
    </>
  );
};

export default MintRunes;
