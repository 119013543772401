import React, { useContext, useEffect, useState } from "react";
import { formatNumber, getBtcValue } from "../../../../../utils/helpers";
import { CmsContext } from "../../../../../context/CmsContext";
import { Token } from "../../../../../utils/types";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedToken } from "../../../../../services/GlobalSlice";
import { formatNumberToMillion, formatNumberToMillionForPriceValue } from "../../../../../utils/common";

interface CardProps {
  item: Token; // Import Token from your types file
}

const Card: React.FC<CardProps> = ({ index, item }: any) => {
  const { btcValue } = useContext(CmsContext)!;
  const selectedToken = useSelector((state: any) => state.global.selectedToken);
  const dispatch = useDispatch();

  const runeData = useSelector((state: any) => state.global.tokens || []);

  // console.log(selectedToken, item, "resddf");

  // console.log(item, "item")
  return (
    <div
      onClick={() => {
        dispatch(setSelectedToken(item));
      }}
      className={`group cursor-pointer px-5 py-[3px] border-l-4 hover:border-[#FFE564] transition-all hover:bg-[#0F0F14]  ${
        selectedToken?.rune === item?.rune
          ? "border-[#FFE564] border-l-4  bg-[#0F0F14]"
          : item?.rune === runeData[0]?.rune && !selectedToken
          ? "border-[#FFE564] border-l-4  bg-[#0F0F14]"
          : "border-transparent bg-transparent"
      }`}
    >
      <div
        className={` border-b-[1px] hover:border-transparent  pb-3  ${
          selectedToken === item
            ? "border-transparent"
            : item?.rune === runeData[0]?.rune && !selectedToken
            ? "border-transparent"
            : "border-[#202026]"
        }`}
      >
        <div className=" flex font-monsterate  justify-between flex-wrap  ">
          <p
            title={item?.etching?.runeName}
            className=" text-[15px] leading-[27px] text-white max-w-[155px] overflow-x-scroll"
          >
            {item?.etching?.runeName}
          </p>
          <div className=" flex flex-wrap flex-col items-end">
            <p title={item?.unitPriceSats+" Sats"} className="text-[15px] text-center leading-[27px] text-white">
              {item.unitPriceSats ? formatNumberToMillion(item.unitPriceSats) : "-"}
              <span className=" ml-1">{item.unitPriceSats && "Sats"}</span>
            </p>
            <span
              title={
                item?.unitPriceSats && btcValue
                  ? `${(item?.unitPriceSats * btcValue) / 10 ** 8}`
                  : "-"
              }
              className="text-[12px] text-center leading-[21px] text-[#7A7A8E]"
            >
              {item?.unitPriceSats && btcValue
                ? `$${formatNumberToMillionForPriceValue(
                    (item?.unitPriceSats * btcValue) / 10 ** 8
                  )}`
                : "-"}
            </span>
          </div>
        </div>
        <div className=" w-full xl:w-[80%] flex justify-between gap-2">
          <button
            type="button"
            className={` flex items-center py-2 px-2 gap-[5px] group-hover:bg-[#000000] rounded ${
              selectedToken === item
                ? "bg-[#000000]"
                : item?.rune === runeData[0]?.rune && !selectedToken
                ? "bg-[#000000]"
                : "bg-[#0F0F14]"
            }`}
          >
            <img src="/contact.svg" alt="arrow" width={12} height={10} />
            <p
              title={`${item.holderCount}`}
              className=" text-[14px] leading-5 text-[#7A7A8E] text-center"
            >
              {item.holderCount
                ? `${formatNumberToMillion(item.holderCount)}`
                : "-"}
            </p>
          </button>
          <button
            type="button"
            className={` flex items-center px-2  gap-[5px] py-2  group-hover:bg-[#000000] rounded ${
              selectedToken === item
                ? "bg-[#000000]"
                : item?.rune === runeData[0]?.rune && !selectedToken
                ? "bg-[#000000]"
                : "bg-[#0F0F14]"
            }`}
          >
            <img src="/histogram.svg" alt="arrow" width={14} height={10} />
            <p
              title={item.marketCap && btcValue && item.marketCap * btcValue}
              className="  text-[14px] leading-5 text-[#7A7A8E]"
            >
              {item.marketCap && btcValue
                ? `$${formatNumberToMillion(item.marketCap * btcValue)}`
                : "-"}
            </p>
          </button>
          <button
            type="button"
            className={` flex items-center px-2 py-2  gap-1 rounded 
          ${item?.unitPriceChange < 0 ? "bg-[#A92B68]/20" : "bg-[#2BBE49]/10"}`}
          >
            <p
              className={` text-[14px] leading-5 ${
                item?.unitPriceChange < 0 ? "text-[#EF348E]" : "text-[#32FF5F]"
              } `}
            >
              {item?.unitPriceChange
                ? `${item?.unitPriceChange?.toFixed(2)}%`
                : "-"}
            </p>
            {item?.unitPriceChange && item?.unitPriceChange ? (
              item?.unitPriceChange && item?.unitPriceChange > 0 ? (
                <img src="/up.svg" alt="arrow" width={14} height={10} />
              ) : (
                <img src="/down.svg" alt="arrow" width={14} height={10} />
              )
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
