import React from "react";
import { cmsText } from "../../utils/types";

type Props = {
  cmsData: cmsText;
};
const DisplayTokens = ({ cmsData }: Props) => {
  return (
    <div className="text-[12px] sm:text-[14px] mt-8 sm:mt-16 w-full sm:w-[80%] lg:w-1/2 px-6 flex flex-col sm:flex-row gap-5 sm:gap-20">
      <div className="flex flex-col gap-5 sm:gap-8">
        <div>
          <img
            className="w-[70px] sm:w-[100px] mt-10"
            src={"/MintToken.svg"}
            alt={"image"}
          />
          <img
            className="w-[100px] sm:w-[200px] mt-5"
            src={"/MintText.svg"}
            alt={"image"}
          />
        </div>
        <div className="text-[14px] font-bold font-monsterate text-white">{cmsData?.mintTokenSubHeading}</div>
        <div className="text-white">
          {cmsData?.mintTokenPara}
        </div>
      </div>
      <div className="flex flex-col gap-5 sm:gap-8">
        <div className="relative">
          <img
            className="w-[70px] sm:w-[90px] mt-10"
            src={"/abcToken.svg"}
            alt={"image"}
          />
          <img
            className="w-[100px] sm:w-[200px] mt-5"
            src={"/OutTokenText.svg"}
            alt={"image"}
          />
        </div>
        <div className="text-[14px] font-bold font-monsterate text-white">{cmsData?.ourTokenSubHeading}</div>
        <div className=" text-white">
          {cmsData?.ourTokenPara}
        </div>
      </div>
    </div>
  );
};

export default DisplayTokens;
