import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { CmsContext } from "../../context/CmsContext";
import React from "react";

const Footer = () => {
  const cmsData = useContext(CmsContext);

  let location = useLocation();

  return (
    <div
      className={` relative  xss:fixed bottom-[-35px] xss:bottom-0 mt-10 items-center text-[12px] backdrop-blur-sm sm:text-[14px] w-full  px-6 flex flex-col justify-between
      ${location.pathname === "/home" ? "sm:w-[80%] lg:w-1/2" : ""}`}
    >
      {(location.pathname === "/home" || location.pathname === "/points") && (
        <img className=" h-[90px]" alt="footer" src={"RunesFooter.svg"} />
      )}
      <div className="w-full py-3 border-t border-white/10 flex justify-between items-center">
        <img
          className="cursor-pointer h-[20px]"
          src="RuneLogo.svg"
          alt="logo"
        />

        <div className="text-[#C8C8C8] font-monsterate">
          {cmsData?.cmsText?.poweredText}{" "}
          <span className="font-bold font-monsterate underline">
            <a href="https://www.czlabs.ch/" target="_blank" rel="noopener">
            {cmsData?.cmsText?.sponsor1}
            </a>
          </span>{" "}
          and{" "}
          <span className="font-bold font-monsterate underline">
            {cmsData?.cmsText?.sponsor2}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
