import React, { useContext } from "react";
import { useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import { CmsContext } from "../../context/CmsContext";

type Props = {
  open: Boolean;
  setOpen: Function;
  fundTxId: string;
};
const SuccessModal = ({ open, fundTxId, setOpen }: Props) => {

  const cmsData = useContext(CmsContext);
  const modalRef = useRef(null);
  return (
    <>
      {open && (
        <div className="fixed top-0 h-screen left-0 bg-black/10 backdrop-blur-md z-[70] w-full">
          <div
            ref={modalRef}
            className="absolute top-1/2 sm:w-[500px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-5 pb-8  min-[480px]:py-10  min-[480px]:px-10 backdrop-blur-[3px]  rounded-xl flex flex-col gap-2 overflow-auto w-fit z-[999] h-fit bg-[#0F0F14]/80 shadow-activeBtnShadow"
            style={{ border: "2px solid rgba(255, 255, 255, 0.15)" }}
          >
            <div className="flex items-center text-white text-[24px] justify-between">
              <h2 className="text-center text-white mb-5 font-bold">
                {/* Transaction Successful{" "} */}
              </h2>
              <RxCross2
                className="text-lg text-gray-500 cursor-pointer relative right-0 top-[-5px]"
                onClick={() => {
                  setOpen(false);
                }}
              />
            </div>
            <div className="w-full flex animate-pulse justify-center">
              <img className="w-[150px]" src={"/BannerImg.png"} alt={"image"} />
            </div>
            <div className="w-full font-monsterate font-bold cursor-pointer text-white mt-4 flex justify-center gap-2 items-center">
              <button
                onClick={() => {
                  if (fundTxId) {
                    window.open(fundTxId);
                  }
                }}
              >
                
                {cmsData?.cmsText?.viewTransaction}
              </button>
              <img
                onClick={() => {
                  if (fundTxId) {
                    window.open(fundTxId);
                  }
                }}
                className="w-4"
                src={"/walletshare.svg"}
                alt={"icon"}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessModal;
