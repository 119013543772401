import { memo, useContext } from "react";
import CardDetails from "./CardDetails";
import TradingView from "./TradingView";
// import { myRunesApi, runesApi } from "../../../../utils/helpers";
import { CmsContext } from "../../../../context/CmsContext";
import React from "react";
import { useSelector } from "react-redux";

// interface GraphType {
//   searchValue: string;
//   setSearchValue: any;
// }

const GraphContainer = memo(() => {
  // const runeData = useSelector((state: any) => state.global.tokens || []);
  const { exploreText, btcValue } = useContext(CmsContext)!;

  const selectedToken = useSelector((state: any) => state.global.selectedToken);

  const chartInitial = useSelector((state: any) => state.global.chartInitial);

  // console.log(btcValue, "sdj");

  return (
    <div className="mt-4 xss:mt-1 pt-4 px-3 w-full h- flex-1 flex flex-col gap-7 md:gap-2">
      <CardDetails
        token={selectedToken ? selectedToken : chartInitial}
        exploreText={exploreText}
        btcValue={btcValue}
      />
      <div className=" h-full rounded-[13px] xs:rounded-[20px] overflow-hidden">
        <TradingView token={selectedToken ? selectedToken : chartInitial} />
      </div>
    </div>
  );
});

GraphContainer.displayName = "GraphContainer";
export default GraphContainer;
