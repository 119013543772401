import { configureStore } from "@reduxjs/toolkit";
import { runesApi } from "./services/runesApi";
import globalReducer from './services/GlobalSlice'; // Adjust the path as necessary


export const store = configureStore({
  reducer: {
    [runesApi.reducerPath]: runesApi.reducer,
    global: globalReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(runesApi.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
