import "./App.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import BaseLayout from "./BaseLayout";
import ExploreTab from "./components/ExploreTab/ExploreTab";
import Home from "./components/Home";
import CmsProvider from "./context/CmsContext";
import PointsTab from "./components/PointsTab/PointsTab";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import PrivateRoute from "./components/PrivateRoute"; // import PrivateRoute

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    children: [
      {
        index: true,
        element: <ExploreTab />,
      },
      // {
      //   element: <PrivateRoute />, // use PrivateRoute here
      //   children: [
      //     {
      //       path: "explore",
      //       element: <ExploreTab />,
      //     },
      //     {
      //       path: "points",
      //       element: <PointsTab />,
      //     },
      //   ],
      // },
      {
        path: "explore",
        element: <ExploreTab />,
      },
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "points",
        element: <PointsTab />,
      },
    ],
  },
]);
function App() {
  useEffect(() => {
    const target = document.getElementById(
      "sats-connect-wallet-provider-selector"
    );
    const observer = new MutationObserver((mutation) => {
      const handleHover = (element: any) => {
        element.style.color = "yellow";
      };

      const handleMouseOut = (element: any) => {
        element.style.color = "white";
      };

      const outerDiv = target?.shadowRoot?.querySelector(":first-child") as HTMLElement;

      const card = target?.shadowRoot?.querySelector(".card") as HTMLElement;
      const cardWidth = target?.shadowRoot?.querySelector(
        ".card-width-container"
      ) as HTMLElement;
      const cardHeight = target?.shadowRoot?.querySelector(
        ".card-height-container"
      ) as HTMLElement;

      const walletGridContainer = target?.shadowRoot?.querySelector(
        ".wallets-grid-container"
      ) as HTMLElement;
      const walletGrid = target?.shadowRoot?.querySelector(
        ".wallets-grid"
      ) as HTMLElement;
      const cardGrid = target?.shadowRoot?.querySelector(
        ".card-grid"
      ) as HTMLElement;
      const closeBtn = target?.shadowRoot?.querySelector(
        ".close-selector-button"
      ) as HTMLElement;
      const leftPanel = target?.shadowRoot?.querySelector(
        ".left-panel"
      ) as HTMLElement;

      // Select the h2 element with the specified id
      const h2Element = target?.shadowRoot?.querySelector(
        'h2[data-part="title"]'
      );
      // Select the para of select wallet text
      const selectWalletText = target?.shadowRoot?.querySelector(
        '[data-part="description"]'
      ) as HTMLElement;
      const rightPanel = target?.shadowRoot?.querySelector(
        ".right-panel"
      ) as HTMLElement;

      const rightPanelContentEmoje = Array.from(
        target?.shadowRoot?.querySelectorAll(".right-panel *") || []
      ).find((el) => el.textContent === "🤔") as HTMLElement; //

      const rightPanelContentPara = Array.from(
        target?.shadowRoot?.querySelectorAll(".right-panel *") || []
      ).find(
        (el) =>
          el.textContent ===
          "Wallets let you send, receive, store and display digital assets like Bitcoin, Stacks, Ordinals & NFTs."
      ) as HTMLElement;

      const rightPanelContentPara2 = Array.from(
        target?.shadowRoot?.querySelectorAll(".right-panel *") || []
      ).find(
        (el) =>
          el.textContent === "Explore Bitcoin apps by connecting your wallet."
      ) as HTMLElement;

      const rightPanelContent2 = Array.from(
        target?.shadowRoot?.querySelectorAll(".right-panel *") || []
      ).find((el) => el.textContent === "What is a wallet?") as HTMLElement;

      const firstChildOfFirstDivInRightPanel =
        target?.shadowRoot?.querySelector(
          ".right-panel > div:first-child > :first-child"
        ) as HTMLElement;

      // Select and style images with alt attributes 'unisat' and 'xverse'
      const imgUnisat = target?.shadowRoot?.querySelector(
        'img[alt="Unisat"]'
      ) as HTMLElement;
      const imgXverse = target?.shadowRoot?.querySelector(
        'img[alt="Xverse Wallet"]'
      ) as HTMLElement;

      const roleBtn = target?.shadowRoot?.querySelector(
        'div[role="button"]'
      ) as HTMLElement;
      const linkBtn = target?.shadowRoot?.querySelector(
        'div[role="link"]'
      ) as HTMLElement;

      
      if (outerDiv) {
        const outerDivFirstChild =  target?.shadowRoot?.querySelector('div[data-part="backdrop"]') as HTMLElement;
        // outerDiv.style.background = "rgba(0,0,0,0.3)";
        outerDiv.style.zIndex = "100";
        // outerDiv.style
      }

      if (cardWidth) {
        cardWidth.style.width = "60%";
        cardWidth.style.maxWidth = "754px";
        cardWidth.style.minWidth = "370px";
      }
      if (cardHeight) {
        cardHeight.style.height = "917px";
      }
      if (closeBtn) {
        // const newCross = document.createElement("div");
        // newCross.innerHTML = ("X");
        // // newCross.className
        closeBtn.style.color = "white";
        closeBtn.style.fontSize = "20px";
        closeBtn.style.position = "absolute";
        closeBtn.style.top = "20px";
        closeBtn.style.right = "15px";
        closeBtn.style.width = "20px";
        closeBtn.style.height = "20px";
        closeBtn.style.zIndex = "100";

        closeBtn.textContent = "✗";

        // closeBtn
      }
      if (card) {
        // Create a container div
        card.style.backgroundColor = "black";
        card.style.display = "flex";
        card.style.flexDirection = "column";
        card.style.alignItems = "center";
        card.style.justifyContent = "center";
        card.style.color = "white";
        card.style.minHeight = "600px";

        cardGrid.style.display = "flex";
        cardGrid.style.flexDirection = "column";
      }

      if (leftPanel) {
        leftPanel.style.justifyContent = "center";
        leftPanel.style.position = "relative";
      }
      if (rightPanel) {
        rightPanel.style.textAlign = "start";
        rightPanel.style.padding = "0 65px 0 75px";
        const rightPanelDiv = rightPanel.children[0].children[0]
          .children[0] as HTMLElement;
        if (rightPanelDiv && rightPanelContent2) {
          rightPanelDiv.style.alignItems = "start";
        }
        if (rightPanelDiv) {
          const rightPanelImg = rightPanel.querySelector(
            "img"
          ) as HTMLImageElement;
          if (rightPanelImg) rightPanelImg.style.alignItems = "center";
        }
      }
      if (walletGridContainer) {
        walletGridContainer.style.overflow = "none";
        walletGridContainer.style.minHeight = "120px";
      }
      if (walletGrid) {
        walletGrid.style.display = "flex";
        walletGrid.style.justifyContent = "center";
        walletGrid.style.gap = "30px";
        walletGrid.style.minHeight = "100px";
      }
      if (h2Element) {
        // Create a new div element with an h1 and h2
        const newDiv = document.createElement("div");
        const h1Element = document.createElement("h1");
        const h1Element2 = document.createElement("h1");
        h1Element.textContent = "Connect Your";
        h1Element2.textContent = "Wallet";
        const h2NewElement = document.createElement("h2");
        h2NewElement.textContent = "Choose Wallet to Connect";

        newDiv.style.margin = "10px";

        // Apply styles to the h1 element
        h1Element.style.color = "#FFE564";
        h1Element.style.textAlign = "center";
        h1Element.style.fontFamily = "Permanent Marker";
        h1Element.style.fontSize = "65px";
        h1Element.style.fontStyle = "italic";
        h1Element.style.fontWeight = "400";
        h1Element.style.lineHeight = "60px"; // 66.17%
        h1Element.style.letterSpacing = "-2.683px";
        h1Element.style.backgroundImage =
          "linear-gradient(to right, #FFE564 0%, #FDA2CE 100%, #ACEC6D 0%, #FFE564 100%)";
        h1Element.style.backgroundClip = "text";
        h1Element.style.color = "transparent";
        h1Element.style.marginTop = "20px";
        h1Element.style.transform = "rotate(-4.736deg)";

        // Apply style to wallet
        h1Element2.style.color = "#FFE564";
        h1Element2.style.textAlign = "center";
        h1Element2.style.fontFamily = "Permanent Marker";
        h1Element2.style.fontSize = "65px";
        h1Element2.style.fontStyle = "italic";
        h1Element2.style.fontWeight = "400";
        h1Element2.style.lineHeight = "60px"; // 66.17%
        h1Element2.style.letterSpacing = "-2.683px";
        h1Element2.style.backgroundImage =
          "linear-gradient(to right, #FFE564 0%, #FDA2CE 100%, #ACEC6D 0%, #FFE564 100%)";
        h1Element2.style.backgroundClip = "text";
        h1Element2.style.color = "transparent";
        h1Element2.style.marginTop = "20px";
        h1Element2.style.transform = "rotate(-4.736deg)";

        // Apply styles to the h1 element
        h2NewElement.style.color = "#FFE564";
        h2NewElement.style.textAlign = "center";
        h2NewElement.style.fontFamily = "Montserrat";
        h2NewElement.style.fontSize = "25px";
        h2NewElement.style.fontStyle = "normal";
        h2NewElement.style.fontWeight = "700";
        h2NewElement.style.lineHeight = "170%"; // 62.464px
        h2NewElement.style.letterSpacing = "-0.347px";
        h2NewElement.style.margin = "5px 0 5px 0 ";

        // Append the image and h2 to the new div
        newDiv.appendChild(h1Element);
        newDiv.appendChild(h1Element2);
        newDiv.appendChild(h2NewElement);

        // Replace the existing h2 with the new div
        h2Element.replaceWith(newDiv);
        // h2Element = true; // Set the flag to true after replacement
      }

      if (imgUnisat) {
        imgUnisat.addEventListener("mouseover", () => handleHover(imgUnisat));
        imgUnisat.addEventListener("mouseout", () => handleMouseOut(imgUnisat));
      }

      if (imgXverse) {
        imgXverse.addEventListener("mouseover", () => handleHover(imgXverse));
        imgXverse.addEventListener("mouseout", () => handleMouseOut(imgXverse));
      }

      const uniSatContainer = walletGrid?.children[1] as HTMLElement;

      const xVerseText = target?.shadowRoot?.querySelector(
        'div[role="button"] div'
      ) as HTMLElement;
      if (xVerseText) {
        xVerseText.style.transition = "all";
        xVerseText.style.color = "white";
        xVerseText.style.padding = "4px";

        roleBtn.addEventListener("mouseenter", () => handleHover(xVerseText));
        roleBtn.addEventListener("mouseleave", () =>
          handleMouseOut(xVerseText)
        );
        roleBtn.addEventListener("focus", () => handleHover(xVerseText));
        roleBtn.addEventListener("blur", () => handleMouseOut(xVerseText));
      }

      const roleButtons =
        target?.shadowRoot?.querySelectorAll('div[role="button"]');
      if (roleButtons) {
        const roleBtn2 = roleButtons?.[1] as HTMLElement;
        const unisatText = roleBtn2?.querySelector(
          'div[role="button"] div'
        ) as HTMLElement;
        if (roleBtn2 && unisatText) {
          unisatText.style.transition = "all";
          unisatText.style.color = "white";
          unisatText.style.padding = "4px";

          roleBtn2.addEventListener("mouseenter", () =>
            handleHover(unisatText)
          );
          roleBtn2.addEventListener("mouseleave", () =>
            handleMouseOut(unisatText)
          );
          roleBtn2.addEventListener("focus", () => handleHover(unisatText));
          roleBtn2.addEventListener("blur", () => handleMouseOut(unisatText));
        }
      }

      if (linkBtn) {
        linkBtn.style.transition = "all";
        linkBtn.style.padding = "0 5px";
        linkBtn.style.marginTop = "10px";
        const xVerseLinkimg = target?.shadowRoot?.querySelector(
          'img[alt="Xverse Wallet"]'
        ) as HTMLElement;
        const xVerseLinkText = target?.shadowRoot?.querySelector(
          'div[role="link"] div'
        ) as HTMLElement;
        if (xVerseLinkimg && xVerseLinkText) {
          xVerseLinkimg.style.border = "4px solid #FD9200";
          xVerseLinkimg.style.strokeWidth = "7.323px";
          xVerseLinkimg.style.stroke = "rgba(253, 146, 0, 0.76)";
          xVerseLinkText.style.color = "white";
          xVerseLinkimg.addEventListener("mouseover", () => {
            xVerseLinkText.style.color = "yellow";
          });
          xVerseLinkimg.addEventListener("focus", () => {
            xVerseLinkText.style.color = "yellow";
          });

          xVerseLinkimg.addEventListener("mouseout", () => {
            xVerseLinkimg.style.color = "white"; // Reset to default color
          });
        }

        // imgUnisat.nextElementSibling as HTMLElement;
        if (xVerseLinkText) {
          xVerseLinkText.style.transition = "all";
          xVerseLinkText.style.color = "white";
          linkBtn.addEventListener("mouseover", () => {
            xVerseLinkText.style.color = "yellow";
          });
          linkBtn.addEventListener("focus", () => {
            xVerseLinkText.style.color = "yellow";
          });
          xVerseLinkText.addEventListener("mouseover", () => {
            xVerseLinkText.style.color = "yellow";
          });

          linkBtn.addEventListener("mouseout", () => {
            xVerseLinkText.style.color = "white"; // Reset to default color
          });
          xVerseLinkText.addEventListener("mouseout", () => {
            xVerseLinkText.style.color = "yellow";
          });
        }
      }

      if (imgUnisat) {
        imgUnisat.style.border = "4px solid #FD9200";
        imgUnisat.style.strokeWidth = "7.323px";
        imgUnisat.style.stroke = "rgba(253, 146, 0, 0.76)";
      }
      if (imgXverse) {
        imgXverse.style.border = "4px solid #FD9200";
      }

      if (selectWalletText) {
        const newDiv1 = document.createElement("div");
        newDiv1.textContent =
          "Start by selecting with one of the wallets below and confirming the connection.";

        selectWalletText.replaceWith(newDiv1);

        // Apply styles to newDiv1
        newDiv1.style.color = "#FFF";
        newDiv1.style.textAlign = "center";
        newDiv1.style.fontFamily = "Montserrat";
        newDiv1.style.fontSize = "14px";
        newDiv1.style.fontStyle = "normal";
        newDiv1.style.fontWeight = "500";
        newDiv1.style.lineHeight = "25px"; // 30.946px
        newDiv1.style.letterSpacing = "-0.172px";
        newDiv1.style.padding = "0 10px 0  15px";
      }
      if (rightPanelContentEmoje) {
        // Create a new span element for the text
        rightPanelContentEmoje.textContent = "";
        rightPanelContentEmoje.style.display = "none";
      }
      if (rightPanelContentPara) {
        rightPanelContentPara.textContent =
          "Wallets let you send, receive, store and display digital assets like Bitcoin, Stacks, Ordinals & NFTs.Explore Bitcoin apps by connecting your wallet.";
      }
      if (rightPanelContentPara2) {
        rightPanelContentPara2.style.display = "none";
      }
    });

    if (target?.shadowRoot) {
      const config = {
        childList: true,
        // attributes: true,
        subtree: true,
        // attributeOldValue: true,
        // characterData: true,
      };
      observer.observe(target?.shadowRoot, config);
    }

    return () => {
      observer.disconnect(); // Disconnect the observer when the component unmounts
    };
  }, []);

  return (
    <Provider store={store}>
      <CmsProvider>
        <RouterProvider router={router} />;
      </CmsProvider>
    </Provider>
  );
}

export default App;
