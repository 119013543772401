export const truncateAddress = (address = "") => {
  return address?.slice(0, 4) + "..." + address?.slice(address?.length - 4);
};

export function formatNumberToMillion(num: number) {
  if (Math.abs(num) < 1000) {
    return num.toFixed(2);
  } else if (Math.abs(num) >= 1000 && Math.abs(num) < 1000000) {
    return (num / 1000).toFixed(2) + "K";
  } else if (Math.abs(num) >= 1000000 && Math.abs(num) < 1000000000) {
    return (num / 1000000).toFixed(2) + "M";
  } else if (Math.abs(num) >= 1000000000) {
    return (num / 1000000000).toFixed(2) + "B";
  }
}

export function formatNumberToMillionForPriceValue(num: number) {
  if (Math.abs(num) < 1000) {
    return num.toFixed(3);
  } else if (Math.abs(num) >= 1000 && Math.abs(num) < 1000000) {
    return (num / 1000).toFixed(3) + "K";
  } else if (Math.abs(num) >= 1000000 && Math.abs(num) < 1000000000) {
    return (num / 1000000).toFixed(3) + "M";
  } else if (Math.abs(num) >= 1000000000) {
    return (num / 1000000000).toFixed(3) + "B";
  }
}