import React, { useState } from "react";
import GraphContainer from "./components/GraphContainer/GraphContainer";
import RightSidebar from "./components/RightSidebar/RightSidebar";
import Sidebar from "./components/Sidebar/Sidebar";
import { CiCircleChevRight, CiCircleChevLeft } from "react-icons/ci";

const ExploreTab: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const [rightOpen, setRightOpen] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");


  return (
    <div className="  md:ml-5 mt-[115px] smxs:mt-[90px] sm:mt-[98px]   relative w-[calc(100vw-20px)] h-full sm:h-[calc(100vh-180px)] flex gap-10">
      {/* Sidebar */}
      <button
        type="button"
        className=" fixed top-[50%] right-0 text-black font-bold bg-custom-gradient text-4xl z-[90] rounded-full"
        onClick={() => setRightOpen(!rightOpen)}
      >
        {
          rightOpen ? 
          <CiCircleChevRight />
        :
          <CiCircleChevLeft />
        }
      </button>
      <button
        type="button"
        className=" lg:hidden fixed top-[50%] left-0 font-bold bg-custom-gradient text-black text-4xl z-[90] rounded-full"
        onClick={() => setOpen(!open)}
      >
       {
          open ? 
          <CiCircleChevLeft />
          :
          <CiCircleChevRight />
        }
      </button>
      <Sidebar open={open} setOpen={setOpen} searchValue={searchValue} setSearchValue={setSearchValue} />
      <GraphContainer />
      <RightSidebar rightOpen={rightOpen} setRightOpen={setRightOpen} />
    </div>
  );
};

export default ExploreTab;
