import { makeApiRequest } from "./helpers";

interface PeriodParams {
  rune: string;
  from: number;
  firstDataRequest: string;
  to: number;
  resolution: string;
  countBack: number;
}

// type Resolution = string;
// type OnHistoryCallback = (bars: Bar[], meta: { noData: boolean }) => void;
// type OnErrorCallback = (error: string | Error) => void;
// type OnSymbolResolvedCallback = (symbolInfo: SymbolInfo) => Promise<void>;
// type OnResolveErrorCallback = (error: string) => void;
type OnResultReadyCallback = (symbols: SymbolInfo[]) => void;
// type OnRealtimeCallback = (bar: Bar) => void;
// type OnResetCacheNeededCallback = () => void;

// interface Bar {
//   time: number;
//   low: number;
//   high: number;
//   open: number;
//   close: number;
//   volume: number;
// }

// interface SymbolItem {
//   ticker: string;
//   symbol: string;
//   description: string;
//   type: string;
//   exchange: string;
//   // Add other properties as needed
// }

interface SymbolInfo {
  ticker: string;
  name: string;
  description: string;
  divisibility: number;
  type: string;
  session: string;
  timezone: string;
  exchange: string;
  minmov: number;
  pricescale: number;
  has_intraday: boolean;
  visible_plots_set: string;
  has_weekly_and_monthly: boolean;
  supported_resolutions: string[];
  volume_precision: number;
  data_status: string;
}

const configurationData = {
  // Represents the resolutions for bars supported by your datafeed

  intraday_multipliers: ["10", "30", "60"],
  supported_resolutions: ["10", "30", "60", "1D", "1W", "1M"],
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [
    { value: "Bitfinex", name: "Bitfinex", desc: "Bitfinex" },
    { value: "Kraken", name: "Kraken", desc: "Kraken bitcoin exchange" },
  ],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ name: "crypto", value: "crypto" }],
};

const lastBarsCache = new Map<string, any>();

const datafeed = {
  onReady: (callback: any) => {
    // console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData));
  },
  // searchSymbols: (
  //   userInput: string,
  //   exchange: string,
  //   symbolType: string,
  //   onResultReadyCallback: OnResultReadyCallback
  // ): void => {
  //   console.log("[searchSymbols]: Method call");
  // },
  resolveSymbol: async (
    symbolName: string,
    onSymbolResolvedCallback: (symbolInfo: SymbolInfo) => void,
    onResolveErrorCallback: (error: string) => void,
    extension: any
  ): Promise<void> => {
    // console.log("[resolveSymbol]: Method call", symbolName);

    try {
      const symbolItem = JSON.parse(symbolName);
      // console.log(symbolItem, "symbol");

      if (!symbolItem) {
        // console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
        onResolveErrorCallback("Cannot resolve symbol");
        return;
      }

      const symbolInfo: any = {
        ticker: symbolItem?.etching?.runeTicker,
        name: symbolItem?.rune,
        description: symbolItem?.etching?.runeName,
        divisibility: symbolItem?.etching?.divisibility,
        type: "crypto",
        session: "24x7",
        timezone: "Etc/UTC",
        exchange: "ABCRunes",
        minmov: 1,
        pricescale: 100,
        has_intraday: true,
        visible_plots_set: "ohlc",
        has_weekly_and_monthly: false,
        supported_resolutions: configurationData.supported_resolutions,
        volume_precision: 2,
        data_status: "streaming",
      };

      // console.log("[resolveSymbol]: Symbol resolved", symbolName);
      onSymbolResolvedCallback(symbolInfo);
    } catch (error) {
      // console.log("[resolveSymbol]: Symbol error", error);
      onResolveErrorCallback("Cannot resolve symbol");
    }
  },
  getBars: async (
    symbolInfo: any,
    resolution: string,
    periodParams: PeriodParams,
    onHistoryCallback: (bars: any[], meta: any) => void,
    onErrorCallback: (error: any) => void
  ): Promise<void> => {
    const { from, to, firstDataRequest, countBack } = periodParams;
    // console.log(
    //   "[getBars]: Method call",
    //   resolution,
    //   from,
    //   to,
    //   countBack
    // );
    // console.log(resolution, "resolution")

    // console.log(symbolInfo,"symbolInfo")

    const typeMap: { [key: string]: string } = {
      "1": "1m",
      "5": "5m",
      "10": "10m",
      "15": "15m",
      "30": "30m",
      "60": "1H",
      "120": "2H",
      "240": "4H",
      "1D": "1D",
      "1W": "1W",
      "1M": "1M",
    };

    const type = typeMap[resolution];
    if (!type) {
      onErrorCallback("Invalid resolution");
      return;
    }

    try {
      // console.log(symbolInfo?.rune, "symbol")
      // const data = await makeApiRequest(symbolInfo.ticker,resolution,from, to,countBack)
      const fixedStartDate = new Date("2024-06-08T00:00:00Z"); // Use UTC time to avoid timezone issues
      const from_time_stamp = fixedStartDate.getTime() / 1000; // Convert to UNIX timestamp in seconds
      // console.log(symbolInfo, "symbol");
      const data = await makeApiRequest(
        symbolInfo?.name,
        // resolution.toLocaleLowerCase(),
        type.toLocaleLowerCase(),
        from_time_stamp * 1000,
        to * 1000,
        countBack
      );

      if (!data || data.length === 0) {
        onHistoryCallback([], { noData: true });
        return;
      }
      // console.log(data, "76576data");
      let bars = data?.map((bar: any) => ({
        time: bar.ts, // Use timestamp for x-axis in charts
        low: symbolInfo?.divisibility
          ? bar.minFP * 10 ** symbolInfo?.divisibility
          : bar.minFP, // Lowest price in the interval
        high: symbolInfo?.divisibility
          ? bar.maxFP * 10 ** symbolInfo?.divisibility
          : bar.maxFP, // Highest price in the interval
        open: symbolInfo?.divisibility
          ? bar.oFP * 10 ** symbolInfo?.divisibility
          : bar.oFP, // Opening price
        close: symbolInfo?.divisibility
          ? bar.cFP * 10 ** symbolInfo?.divisibility
          : bar.cFP, // Closing price
        volume: bar.cV, // Volume at close, zero here
      }));
      // console.log(bars, "bars")

      if (firstDataRequest) {
        lastBarsCache.set(symbolInfo.base_address, {
          ...bars[bars.length - 1],
        });
      }

      // console.log(`[getBars]: returned ${bars.length} bar(s)`);
      if (bars.length < 1) {
        onHistoryCallback([], { noData: true });
      } else {
        onHistoryCallback(bars, { noData: false });
      }
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },
  // subscribeBars: {},
  // unsubscribeBars: {},
};

export default datafeed;
