import React, { useContext, useEffect, useRef, useState } from "react";
import { CmsContext } from "../../context/CmsContext";
// import { pointsApi } from "../../utils/helpers";
import { useGetPointsQuery } from "../../services/runesApi";
import { useDispatch, useSelector } from "react-redux";
import { setPointsData, setRunePoints } from "../../services/GlobalSlice";

const PointsTab = () => {
  const [season, setSeason] = useState<String>("one");

  const [claimRunes, setClaimRunes] = useState<boolean>(false);
  const runePoints = useSelector((state: any) => state.global.runePoints);

  const { pointsText, walletAddress }: any = useContext(CmsContext);

  const points = useSelector((state: any) => state.global.points);

  return (
    <div className="w-full h-[100vh-265px] 2xl:h-full mt-32 xs:mt-36 md:mt-[130px] lg:mt-[140px] mb-[150px] px-1 md:px-6 flex flex-col justify-center items-center gap-3">
      <img
        className="w-[50%] xs:w-[55%] md:w-[40%] "
        src={"/runes_points.png"}
        alt="points"
      />
      <div className=" bg-[#0F0F14] w-full xs:w-[400px] md:w-[652px] py-6 px-5 md:py-10 md:px-10 rounded-xl flex flex-col gap-9">
        <div className=" w-full md:w-[60%] mx-auto flex justify-between items-center">
          <div
            onClick={() => setSeason("one")}
            className={` flex gap-1 items-center transition-all cursor-pointer border px-2 sm:px-3 py-1 bg-[#ACEC6D] border-[#ACEC6D] rounded-lg ${
              season === "one"
                ? "opacity-100 border-opacity-100"
                : "bg-opacity-50 border-opacity-0"
            }`}
          >
            <h3 className=" font-monsterate font-bold text-[14px] leading-[25px]">
              {/* Season 1 */}
              {pointsText?.season1}
            </h3>
          </div>
          <div
            onClick={() => setSeason("two")}
            className={`flex gap-1 items-center transition-all cursor-pointer border px-2 sm:px-3 py-1 bg-[#ACEC6D] border-[#ACEC6D] rounded-lg ${
              season === "two"
                ? "opacity-100 border-opacity-100"
                : "bg-opacity-50 border-opacity-0"
            }`}
          >
            <h3 className=" font-monsterate font-bold text-[14px] leading-[25px]">
              {/* Season 2 */}
              {pointsText?.season2}
            </h3>
          </div>
          <div
            onClick={() => setSeason("three")}
            className={`flex gap-1 items-center transition-all cursor-pointer border px-2 sm:px-3 py-1 bg-[#ACEC6D] border-[#ACEC6D] rounded-lg ${
              season === "three"
                ? "opacity-100 border-opacity-100"
                : "bg-opacity-50 border-opacity-0"
            }`}
          >
            <h3 className=" font-monsterate font-bold text-[14px] leading-[25px]">
              {pointsText?.season3}
            </h3>
          </div>
        </div>

        {season === "one" ? (
          <>
            <div className=" flex flex-col gap-4 ">
              <div className="flex justify-between items-center border-b-[2px] border-[#202026] py-[6px]">
                <div>
                  <p className=" text-[18px] leading-5 md:text-[24px] md:leading-[24px] font-medium font-monsterate text-white ">
                    {pointsText?.holdAbcRunes}
                  </p>
                  <span className=" text-[15px] leading-[18px] font-medium font-monsterate text-[#7A7A8E]">
                    {pointsText?.multiplier2}
                  </span>
                </div>
                <p
                  className={` text-[18px] mr-4 leading-5 md:text-[24px] md:leading-[24px]  font-semibold font-monsterate ${
                    points?.isHolding10000Rune
                      ? "text-[#ACEC6D]"
                      : "text-[#B1276A]"
                  }`}
                >
                  {points?.isHolding10000Rune
                    ? pointsText?.yes
                    : pointsText?.no}
                </p>
              </div>
              <div className=" flex justify-between items-center border-b-[2px] border-[#202026] py-[6px]">
                <div>
                  <p className="text-[18px] leading-5 md:text-[24px] md:leading-[28px]  font-medium font-monsterate text-white ">
                    {pointsText?.holdAbcOrdinals}
                  </p>
                  <span className=" text-[15px] leading-[18px] font-medium font-monsterate text-[#7A7A8E]">
                    {pointsText?.multiplier1}
                  </span>
                </div>
                <p
                  className={` text-[18px] mr-4 leading-5 md:text-[24px] md:leading-[24px]  font-semibold font-monsterate ${
                    points?.isHoldingAbc ? "text-[#ACEC6D]" : "text-[#B1276A]"
                  }`}
                >
                  {points?.isHoldingAbc ? pointsText?.yes : pointsText?.no}
                </p>
              </div>
              <div className=" flex justify-between items-center border-b-[2px] border-[#202026] py-[6px]">
                <div>
                  <p className="text-[18px] leading-5 md:text-[24px] md:leading-[24px]  font-medium font-monsterate text-white ">
                    {pointsText?.totalAbcPoints}
                  </p>
                  <span className=" text-[15px] leading-[18px] font-medium font-monsterate text-[#7A7A8E]">
                    {pointsText?.updatesEverySec}
                  </span>
                </div>
                <div className=" flex gap-1 items-center border border-[#202026] px-4 rounded-3xl">
                  <img
                    src="/greenStar.svg"
                    alt="points"
                    className=""
                    width={20}
                    height={20}
                  />
                  <p className=" text-[15px] smx:text-[20px] font-monsterate leading-[38px] text-white font-semibold">
                    {walletAddress.length !== 0 && runePoints
                      ? Number(runePoints).toLocaleString()
                      : 0}
                  </p>
                </div>
              </div>
            </div>

            {claimRunes ? (
              <button
                type="button"
                onClick={() => setClaimRunes(!claimRunes)}
                className={` w-full hover:shadow-glowShadow font-monsterate py-3 rounded-lg font-bold bg-custom-gradient`}
              >
                {pointsText?.claimRunes}
              </button>
            ) : (
              <button
                type="button"
                disabled={!claimRunes}
                onClick={() => setClaimRunes(false)}
                className={` w-full hover:shadow-glowShadow hover:opacity-70 font-monsterate opacity-60 py-3 rounded-lg disabled:cursor-not-allowed font-bold bg-custom-gradient `}
              >
                {pointsText?.comingSoon}
              </button>
            )}
          </>
        ) : (
          (season === "two" || season === "three") && (
            <div className="min-h-[304px] flex items-center justify-center ">
              <div className=""></div>
              <button
                type="button"
                className={` w-[50%] hover:shadow-glowShadow font-monsterate opacity-70 py-3 rounded-lg font-bold bg-custom-gradient disabled:cursor-not-allowed `}
                disabled={true}
              >
                {pointsText?.comingSoon}
              </button>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default PointsTab;
