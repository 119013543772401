import { HTMLAttributes } from "react";
import { Address, BitcoinNetworkType } from "sats-connect";
import { useLocalStorage } from "./hooks";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import React from "react";

const MainContainer = ({
  children,
  className,
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={["min-h-[calc(100vh-270px)]", className || " "].join(" ")}>
      {children}
    </div>
  );
};

const BaseLayout = () => {
  const [network, setNetwork] = useLocalStorage<BitcoinNetworkType>(
    "network",
    BitcoinNetworkType.Mainnet
  );
  const [addressInfo, setAddressInfo] = useLocalStorage<Address[]>(
    "addresses",
    []
  );

  return (
    <>
      <div className=" flex flex-col items-center">
        <Navbar
          network={network}
          setNetwork={setNetwork}
        />
        <MainContainer>
          <Outlet />
        </MainContainer>
        <Footer />
      </div>
    </>
  );
};

export default BaseLayout;
